import { FeatureFlags, defaultFlags } from '../types';

class EnvFeatureFlagService {
  private flags: FeatureFlags;

  constructor() {
    this.flags = this.initializeFlags();
  }

  private initializeFlags(): FeatureFlags {
    return {
      CMS_EDITING_DISABLED: this.getBooleanFlag(
        'REACT_APP_CMS_EDITING_DISABLED',
        defaultFlags.CMS_EDITING_DISABLED,
      ),
      SHOW_MAINTENANCE_BANNER: this.getBooleanFlag(
        'REACT_APP_SHOW_MAINTENANCE_BANNER',
        defaultFlags.SHOW_MAINTENANCE_BANNER,
      ),
    };
  }

  private getBooleanFlag(envKey: string, defaultValue: boolean): boolean {
    const value = process.env[envKey];
    if (value == null) return defaultValue;
    return value.toLowerCase() === 'true';
  }

  public isOn(flagName: keyof FeatureFlags): boolean {
    return this.flags[flagName];
  }
}

export const envFeatureFlagService = new EnvFeatureFlagService();
