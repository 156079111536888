import ky, { Options } from 'ky';
import { logOut } from './userHelper';

export const beforeRequest = (request: Request, onMissingJwt?: () => void) => {
  const jwt = localStorage.getItem('jwt');

  //AT 9/5/2022: Potential future enhancement...any routes/request that arent open
  /*const openRoutes = ['api/AuthenticateUser', etc..];
  if (request.url.toLowerCase().includes(...)
      && !jwt) {
    onMissingJwt?.();
    return;
  }*/
  request.headers.set('x-functions-key', process.env.REACT_APP_API_KEY);
  request.headers.set('Authorization', `Bearer ${jwt}`);
};

export const afterResponse = (
  request,
  options,
  response,
  onUnauthorized?: () => void,
) => {
  if (response.status === 401) {
    return onUnauthorized?.();
  }
};

const api = ky.extend({
  prefixUrl: `${process.env.REACT_APP_API_URL}/api`,
  timeout: 180_000,
  retry: 0,
  hooks: {
    beforeRequest: [(request: Request) => beforeRequest(request, logOut)],
    afterResponse: [(...args) => afterResponse(...args, logOut)],
  },
});

export async function apiGet(
  url: string,
  options?: Options,
  queryParams?: {},
): Promise<any> {
  const query = queryParams ? new URLSearchParams(queryParams).toString() : '';
  return await api.get(url + (query ? `?${query}` : ''), options).json();
}

export async function apiGetFile(
  url: string,
  options?: Options,
  queryParams?: {},
): Promise<any> {
  const query = queryParams ? new URLSearchParams(queryParams).toString() : '';
  return await api.get(url + (query ? `?${query}` : ''), options).blob();
}

export async function apiPost(
  url: string,
  data: {},
  options?: Options,
): Promise<any> {
  return await api
    .post(url, {
      ...options,
      json: data,
    })
    .json();
}

export async function apiPut(
  url: string,
  data: {},
  options?: Options,
): Promise<any> {
  return await api
    .put(url, {
      ...options,
      json: data,
    })
    .json();
}

export async function apiPatch(
  url: string,
  data: {},
  options?: Options,
): Promise<any> {
  return await api
    .patch(url, {
      ...options,
      json: data,
    })
    .json();
}

export async function apiDelete(
  url: string,
  options?: Options,
  queryParams?: {},
): Promise<any> {
  const query = queryParams ? new URLSearchParams(queryParams).toString() : '';
  return await api.delete(url + (query ? `?${query}` : ''), options).json();
}
export class PaymentServicesApiError extends Error {
  constructor(message: string, name: string = 'PaymentServicesApiError') {
    super(message);
    this.name = name;
  }
}

export const afterResponsePayments = async (
  request,
  options,
  response,
  onUnauthorized?: () => void,
) => {
  if (response.status === 401) {
    return onUnauthorized?.();
  }

  if (!response.ok) {
    let resJson = await response.json();
    throw new PaymentServicesApiError(resJson.error);
  }
};

const apiPayments = ky.extend({
  prefixUrl: `${process.env.REACT_APP_PAYMENT_API_URL}/api`,
  timeout: 60000,
  retry: 0,
  hooks: {
    beforeRequest: [(request: Request) => beforeRequest(request, logOut)],
    afterResponse: [(...args) => afterResponsePayments(...args, logOut)],
  },
});

export async function apiPaymentsPost(
  url: string,
  data: {},
  options?: Options,
): Promise<any> {
  return await apiPayments
    .post(url, {
      ...options,
      json: data,
    })
    .json();
}

export default api;
