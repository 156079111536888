import { Box, Link, Container } from '@mui/material';
import Typography from '@mui/material/Typography';
import * as React from 'react';

type CMSMaintenanceMessageProps = {
  variant?: 'full' | 'banner';
};

export const CMSMaintenanceMessage: React.FC<CMSMaintenanceMessageProps> = ({
  variant = 'full',
}) => {
  const styles = {
    banner: {
      width: '100%',
      backgroundColor: '#fff3e0',
      padding: {
        xs: '8px 14px',
        md: '12px 24px',
      },
      borderBottom: '1px solid #ffe0b2',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    },
    full: {
      maxWidth: '800px',
      margin: '40px auto',
      padding: '24px',
      backgroundColor: '#fff3e0',
      borderRadius: '8px',
    },
  };

  const content = (
    <>
      <Typography
        sx={{
          flex: '1 1 100%',
          fontSize: variant === 'banner' ? { xs: '12px', md: '14px' } : '16px',
          marginBottom: variant === 'banner' ? 0.25 : 2, // Reduced from 0.5 to 0.25
        }}
        variant="h6"
        component="div"
      >
        Scheduled Wedding Website Maintenance Notification
      </Typography>

      <>
        <Typography
          sx={{
            flex: 1,
            mb: variant === 'banner' ? 0.5 : 2, // Reduced from 2 to 0.5 for banner
            fontSize:
              variant === 'banner' ? { xs: '12px', md: '14px' } : '16px',
          }}
          variant="subtitle1"
        >
          We wanted to inform you that our website will undergo scheduled
          maintenance on the evening of February 6th, through the morning of
          February 7th, 2025. <br /> During this time, certain aspects of the
          site may be temporarily unavailable.
        </Typography>

        <Typography
          sx={{
            flex: '1 1 100%',
            mb: variant === 'banner' ? 0.5 : 2, // Reduced from 2 to 0.5 for banner
            fontSize:
              variant === 'banner' ? { xs: '12px', md: '14px' } : '16px',
          }}
          variant="h6"
        >
          Expected Maintenance Window: Midnight – 5:00 AM Central Time
        </Typography>
      </>

      <Typography
        sx={{
          flex: 1,
          fontSize:
            variant === 'banner' ? { xs: '12px', md: '12px' } : 'inherit',
        }}
        variant="subtitle1"
      >
        Questions? Contact{' '}
        <Link
          href="mailto:guestservices@destify.com"
          target="_blank"
          rel="noreferrer"
        >
          GuestServices@destify.com
        </Link>
      </Typography>
    </>
  );

  return variant === 'banner' ? (
    <Box sx={styles.banner}>{content}</Box>
  ) : (
    <Container>
      <Box sx={styles.full}>{content}</Box>
    </Container>
  );
};
