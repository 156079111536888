import React, { useCallback, useState, useEffect } from 'react';

import {
  Alert,
  Box,
  Card,
  Typography,
  debounce,
  useTheme,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import AppRoute from '../../../../utils/appRoute';
import { AppTextField } from '../../../components/Form/AppTextField';
import { IconImageUpload } from '../../../components/Icons/IconImageUpload';
import { FadeInContainer } from '../../../components/Misc/FadeInContainer';
import { RoundedButton } from '../../../components/RoundedButton';
import './fonts.css';
import { ImageUploadModal } from './ImageUploadModal';
import { useWeddingWebsiteSlice } from './slice';
import { selectWebsiteDetail, selectIsUpdating } from './slice/selectors';
import { WeddingPartyMember, WebsiteDetail } from './slice/types';
import { IconDelete } from 'app/components/Icons/IconDelete';
import { IconEdit } from 'app/components/Icons/IconEdit';
import { IconTrash } from 'app/components/Icons/IconTrash';
import { IconPlusCircled } from 'app/components/Icons/IconPlusCircled';
import { AddButton } from './sharedComponents';
import AppSelect from 'app/components/Form/AppSelect';
import { generateFileNameForBase64EncodedImage, notify } from 'utils/misc';
import { parseHtmlBackIntoText } from 'utils/formattingHelper';
import { RichTextEditor } from 'app/components/Form/RichTextEditor';
import { WeddingPartySkeleton } from 'app/components/Skeletons/WeddingPartySkeleton';
import { WeddingWebsiteLayout } from './WeddingWebsiteLayout';
import { pageNameList } from 'app/components/EditYourWebsiteMenu';
import LoadingModal from 'app/components/Misc/LoadingModal';

const weddingPartyRoles = [
  'Maid of Honor',
  'Matron of Honor',
  'Best Man',
  'Groomsman',
  'Bridesmaid',
  'Junior Bridesmaid',
  'Junior Groomsman',
  'Flower Girl',
  'Ring Bearer',
  'Usher',
  'Officiant',
  'Mother of the Bride',
  'Father of the Bride',
  'DJ',
  'Honorary Best Woman',
  'Father of the Groom',
  'Mother of the Groom',
  'Groomswoman',
  'Best Woman',
  'Other',
];

const schema = yup
  .object({
    name: yup.string().required(),
    roles: yup.string().required(),
    description: yup
      .string()
      .required()
      .test(
        'max',
        ' Your description must be less than 700 characters',
        description => parseHtmlBackIntoText(description).length < 701,
      ),

    weddingPartyTitleOther: yup
      .string()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .max(35, 'Your Title must be less than 35 characters')
      .matches(/^[A-Za-z ]+$/, 'Your Title must contain only alphabets.'),
  })
  .required();

export function WeddingWebsiteWeddingParty() {
  const websiteDetail: WebsiteDetail = useSelector(selectWebsiteDetail);
  const dispatch = useDispatch();
  const { actions } = useWeddingWebsiteSlice();
  const isUpdating = useSelector(selectIsUpdating);

  const bridesmaids: WeddingPartyMember[] = websiteDetail
    ? websiteDetail?.bridesmaids
    : [];
  const groomsmen: WeddingPartyMember[] = websiteDetail
    ? websiteDetail?.groomsmen
    : [];

  const theme = useTheme();
  const [dialog, setDialog] = useState({
    open: false,
    title: 'Delete Wedding Party',
    message: '',
    data: null,
  });
  const handleCancel = () => {
    setDialog({ ...dialog, open: false });
  };

  const handleOk = () => {
    dispatch(
      actions.deleteWeddingParty({
        contentKey: dialog.data.person.contentKey,
        isRight: dialog.data.side === 'right' ? true : false,
      }),
    );
    setDialog({ ...dialog, open: false });
  };

  return (
    <WeddingWebsiteLayout
      title={'Wedding Party'}
      currentPageName={pageNameList.weddingParty}
    >
      {!groomsmen.length && !bridesmaids.length && (
        <Alert severity="info">
          <Typography variant={'body1'}>
            Add your guests here to populate the left and right columns of your
            wedding website's Wedding Party section{' '}
          </Typography>
        </Alert>
      )}
      <Box
        sx={{
          display: 'grid',
          gridTemplateAreas: `
                  'leftColumn rightColumn'`,
          gridGap: '1rem',
          gridTemplateColumns: '1fr 1fr',
          width: '100%',
          [theme.breakpoints.down('md')]: {
            gridTemplateAreas: `
                    'leftColumn'
                    'rightColumn'`,
            gridTemplateColumns: '1fr',
          },
        }}
      >
        <WeddingPartyColumn
          type="bridesmaids"
          side={'left'}
          people={websiteDetail?.bridesmaids}
          dialog={dialog}
          setDialog={setDialog}
        />
        <WeddingPartyColumn
          type="groomsmen"
          side={'right'}
          people={websiteDetail?.groomsmen}
          dialog={dialog}
          setDialog={setDialog}
        />
      </Box>
      <Dialog
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
        maxWidth="xs"
        open={dialog.open}
      >
        <DialogTitle>{dialog.title}</DialogTitle>
        <DialogContent dividers>{dialog.message}</DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button autoFocus onClick={handleOk}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      {isUpdating && <LoadingModal open={true}></LoadingModal>}
    </WeddingWebsiteLayout>
  );
}

const WeddingPartyColumn = ({
  side,
  people,
  type,
  dialog,
  setDialog,
}: {
  side: 'right' | 'left';
  people?: WeddingPartyMember[];
  type: string;
  dialog: object;
  setDialog: Function;
}) => {
  const [isAdd, setIsAdd] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedPerson, setSelectedPerson] =
    useState<WeddingPartyMember | null>(null);
  const [columnLoading, setColumnLoading] = useState(false);

  const onClickAdd = () => {
    setIsAdd(true);
    setSelectedPerson(null);
  };

  const handleEdit = (person: WeddingPartyMember) => {
    setSelectedPerson(person);
    setIsEdit(true);
  };

  const onDelete = (person: WeddingPartyMember, side) => {
    setDialog({
      ...dialog,
      open: true,
      message: `Do you want to delete '${person.name}'? `,
      data: { person, side },
    });
  };

  const websiteDetail: WebsiteDetail = useSelector(selectWebsiteDetail);

  useEffect(() => {
    if (websiteDetail) {
      setColumnLoading(false);
    }
  }, [websiteDetail]);

  if (columnLoading) {
    return (
      <Box key={type} gridArea={`${side}Column`} padding={'1rem 0.5rem'}>
        <Typography
          sx={{ textTransform: 'capitalize' }}
        >{`${side} Column`}</Typography>
        <WeddingPartySkeleton />
      </Box>
    );
  }

  return (
    <Box key={type} gridArea={`${side}Column`} padding={'1rem 0.5rem'}>
      <Typography
        sx={{ textTransform: 'capitalize' }}
      >{`${side} Column`}</Typography>
      <Box>
        {isAdd || isEdit ? (
          <Box>
            <WeddingPartyForm
              person={isEdit ? selectedPerson : null}
              onCancel={() => {
                setIsAdd(false);
                setIsEdit(false);
              }}
              afterSubmit={() => {
                setIsAdd(false);
                setIsEdit(false);
                setColumnLoading(true);
              }}
              side={side}
              isEdit={isEdit}
            />
          </Box>
        ) : (
          <FadeInContainer>
            {people?.map((person: WeddingPartyMember, index) => (
              <WeddingPartyCard
                index={index}
                person={person}
                onEdit={handleEdit}
                onDelete={onDelete}
                side={side}
                key={index}
              />
            ))}

            <Box
              sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}
            >
              <AddButton
                role="button"
                sx={{
                  cursor: 'pointer',
                }}
                onClick={onClickAdd}
              >
                <IconPlusCircled />
              </AddButton>
            </Box>
          </FadeInContainer>
        )}
      </Box>
    </Box>
  );
};

const WeddingPartyForm = ({
  afterSubmit,
  onCancel,
  isEdit = false,
  person,
  side,
}: {
  afterSubmit: () => void;
  onCancel: () => void;
  isEdit?: boolean;
  person?: WeddingPartyMember | null;
  side?: 'left' | 'right';
}) => {
  const dispatch = useDispatch();
  const { actions } = useWeddingWebsiteSlice();
  const history = useHistory();
  const websiteDetail = useSelector(selectWebsiteDetail);

  const [openImageUploadModal, setOpenImageUploadModal] =
    useState<boolean>(false);

  const [role, setRole] = useState<any>(
    isEdit ? parseHtmlBackIntoText(person?.roles) : '',
  );
  const [name, setName] = useState<string>(
    isEdit ? parseHtmlBackIntoText(person?.name) : '',
  );
  const [weddingPartyTitleOther, setWeddingPartyTitleOther] = useState<string>(
    isEdit ? person?.weddingPartyTitleOther : '',
  );
  const [description, setDescription] = useState<string>(
    isEdit ? person?.description : '',
  );

  const [fileName, setFileName] = useState<string>(
    isEdit ? person?.fileName : '',
  );

  const [base64EncodedImageData, setBase64EncodedImageData] = useState<string>(
    isEdit ? person?.base64EncodedImageData : '',
  );

  const [imageUrl, setImageUrl] = useState<string>(
    isEdit ? person?.images?.url : '',
  );

  const [imageId, setImageId] = useState<number>(
    isEdit ? person?.images?.id : null,
  );

  const [errors, setErrors] = useState({});

  useEffect(() => {
    setImageUrl(person?.images?.url ? person?.images?.url : '');
    setImageId(person?.images?.id ? person?.images?.id : null);
  }, [person]);

  const onSubmit = () => {
    const partySubset = side === 'left' ? 'bridesmaids' : 'groomsmen';
    try {
      if (!fileName && !base64EncodedImageData && !imageUrl) {
        throw new Error('Please select a photo');
      }
      if (role === 'Other' && !weddingPartyTitleOther) {
        throw new Error('Please select or type Wedding party title');
      }
      const newPerson = {
        name,
        roles: role,
        images: null,
        weddingPartyTitleOther: role === 'Other' ? weddingPartyTitleOther : '',
        contentKey: person?.contentKey || '',
        description: description,
        uploadRequest: imageUrl
          ? null
          : {
              fileName,
              base64EncodedImageData: base64EncodedImageData
                ? base64EncodedImageData.split(',')[1]
                : '',
            },
      };
      if (isEdit) {
        newPerson.images = { url: imageUrl || '', id: imageId || null };
      }
      schema.validateSync(newPerson, { abortEarly: true });
      const filteredData = websiteDetail[partySubset].filter(
        // remove the person we are editing
        p => p.contentKey !== newPerson.contentKey,
      );
      const formData = {
        // need to send to api without .destify.com for now
        domainName: websiteDetail.websiteUrl ? websiteDetail.websiteUrl : '',
        [partySubset]: [
          ...filteredData,
          {
            ...newPerson,
          },
        ],
      };
      dispatch(
        actions.requestWebsiteEdit({
          formData,
          history,
          route: AppRoute.weddingWebsiteWeddingPartyPage(),
        }),
      );
      setBase64EncodedImageData(null);
      setFileName(null);
      afterSubmit();
    } catch (e: any) {
      let msg = e.message;
      if (e.errors?.length > 0) {
        msg = (
          <div>
            {e.errors.map((t, i) => (
              <div key={i}>{t}</div>
            ))}
          </div>
        );
      }
      notify('', msg, 'danger');
      dispatch(actions.setLoading(false));
    }
  };

  const onUploadImage = useCallback(base64Image => {
    setFileName(generateFileNameForBase64EncodedImage(base64Image));
    setBase64EncodedImageData(base64Image);
  }, []);

  const onClickDeleteImage = useCallback(() => {
    setBase64EncodedImageData(null);
    setImageUrl(null);
  }, []);

  const handleChangeDescription = debounce(value => {
    let _value = parseHtmlBackIntoText(value) === '' ? '' : value;
    try {
      schema.validateSyncAt('description', {
        description: _value,
      });
      if (errors['description']) {
        setErrors(prevErrors => ({ ...prevErrors, description: undefined }));
      }
    } catch (err: any) {
      setErrors({ description: err.message });
    }
    setDescription(_value);
  }, 300);
  return (
    <FadeInContainer>
      <form id="wedding-party-add-form">
        <AppTextField
          inputProps={{
            'data-testid': 'wedding-party-name',
          }}
          sx={{ m: '0 0 16px 0', bgcolor: 'white' }}
          placeholder="Name"
          size="medium"
          type="text"
          variant="outlined"
          fullWidth
          ref={null}
          value={name}
          onChange={e => setName(e.target.value)}
        />
        <AppSelect
          inputProps={{
            'data-testid': 'wedding-party-role',
          }}
          sx={{ m: '0 0 16px 0', bgcolor: 'white' }}
          value={role}
          placeholder="Role"
          size="medium"
          type="text"
          variant="outlined"
          fullWidth
          onChange={e => setRole(e.target.value)}
          options={weddingPartyRoles.map(role => ({
            label: role,
            value: role,
          }))}
        />
        <AppTextField
          inputProps={{
            'data-testid': 'wedding-party-title-other',
          }}
          sx={{ m: '0 0 16px 0', bgcolor: 'white' }}
          placeholder="Wedding Party Other"
          size="medium"
          type="text"
          variant="outlined"
          disabled={role !== 'Other'}
          fullWidth
          ref={null}
          value={weddingPartyTitleOther}
          onChange={e => setWeddingPartyTitleOther(e.target.value)}
        />

        {/* As RichTextEditor do not rerender providing props editable, adding disable TextField */}
        {role === 'Other' ? (
          <RichTextEditor
            placeholder="Description"
            onChange={val => handleChangeDescription(val)}
            defaultValue={isEdit ? person.description : ''}
            maxCharacters={700}
            toolBar={false}
            error={errors['description']}
          />
        ) : (
          <AppTextField
            sx={{ m: '0 0 16px 0', bgcolor: 'white' }}
            placeholder="Description"
            size="medium"
            type="text"
            variant="outlined"
            disabled
            fullWidth
            rows={5}
            ref={null}
            multiline
          />
        )}

        <ImageUploader
          onClickDeleteImage={onClickDeleteImage}
          imageSelected={{ fileName, base64EncodedImageData, imageUrl }}
          onClick={() => setOpenImageUploadModal(true)}
        />
        <Box display={'flex'} justifyContent={'space-between'} mt={2}>
          <RoundedButton
            className={'white medium'}
            data-testid="wedding-party-delete-button"
            onClick={onCancel}
          >
            Cancel
          </RoundedButton>
          <RoundedButton
            className={'medium'}
            data-testid="wedding-party-save-button"
            onClick={onSubmit}
            disabled={Object.values(errors).filter(item => !!item).length}
          >
            Save
          </RoundedButton>
        </Box>
      </form>
      <ImageUploadModal
        open={openImageUploadModal}
        onClose={() => setOpenImageUploadModal(false)}
        onUpload={onUploadImage}
        aspect={1 / 1}
      />
    </FadeInContainer>
  );
};

const WeddingPartyCard = ({
  index,
  person,
  onEdit,
  onDelete,
  side,
}: {
  index: number;
  person: WeddingPartyMember;
  onEdit: (person: WeddingPartyMember) => void;
  onDelete: (person: WeddingPartyMember, side: String) => void;
  side: String;
}) => {
  return (
    <Card
      key={index}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '1rem',
        width: '100%',
        marginBottom: '1rem',
      }}
    >
      <Box>
        <Typography fontWeight={'bold'}>{person.name}</Typography>
        <Typography>{person.roles}</Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-end',
        }}
      >
        <Box
          role={'button'}
          onClick={() => {
            onEdit(person);
          }}
        >
          <IconEdit fill="#fff" height={20} />
        </Box>
        <Box
          role={'button'}
          sx={{ cursor: 'pointer' }}
          onClick={() => {
            onDelete(person, side);
          }}
        >
          <IconTrash height={20} />
        </Box>
      </Box>
    </Card>
  );
};

const ImageUploader = ({
  onClick,
  imageSelected,
  onClickDeleteImage,
}: {
  onClick: () => void;
  imageSelected: {
    fileName: string;
    base64EncodedImageData: string;
    imageUrl: string;
  } | null;
  onClickDeleteImage: () => void;
}) => {
  return (
    <>
      {imageSelected?.base64EncodedImageData || imageSelected?.imageUrl ? (
        <Box
          sx={{
            position: 'relative',
            width: 320,
            height: 320,
            borderRadius: '20px',
            backgroundImage: `url("${
              imageSelected?.base64EncodedImageData || imageSelected?.imageUrl
            }")`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: '8px',
              right: '8px',
              zIndex: 9,
              cursor: 'pointer',
            }}
            data-testid="save-the-date-delete-image-button"
            onClick={onClickDeleteImage}
          >
            <IconDelete />
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            width: '320px',
            height: '320px',
            border: '1px dashed #949696',
            borderRadius: '20px',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
            cursor: 'pointer',
          }}
          onClick={onClick}
        >
          <IconImageUpload width={100} height={100} />
          <Typography variant="h6" sx={{ textDecoration: 'underline' }}>
            Upload your Image
          </Typography>
        </Box>
      )}
    </>
  );
};
