// src/app/GuestView/pages/WeddingWebsite/themeConstants.ts
export interface ThemeOption {
  label: string;
  value: ThemeOptionValue;
  color: string;
}
export enum ThemeOptionValue {
  CustomDesign = 'CustomDesign',
  BlushCelebrationNavy = 'BlushCelebration-Navy',
  BlushCelebrationPink = 'BlushCelebration-Pink',
  LilacsCoolGrey = 'Lilacs-CoolGrey',
  LilacsGhostWhite = 'Lilacs-GhostWhite',
  LilacsWisteria = 'Lilacs-Wisteria',
  LovelyLacePudra = 'LovelyLace-Pudra',
  LovelyLaceLatteFroth = 'LovelyLace-LatteFroth',
  ModernCopperBlack = 'ModernCopper-Black',
  ModernCopperGold = 'ModernCopper-Gold',
  PalmLeavesIvory = 'PalmLeaves-Ivory',
  PalmLeavesPowderBlue = 'PalmLeaves-PowderBlue',
  SeashellsLatteFroth = 'Seashells-LatteFroth',
  SeashellsChanoyu = 'Seashells-Chanoyu',
  SeashellsBauhausBuff = 'Seashells-BauhausBuff',
  SeashellsSkydiver = 'Seashells-Skydiver',
  SpringLightPink = 'Spring-LightPink',
  SpringSand = 'Spring-Sand',
  SunflowersGoldenRod = 'Sunflowers-GoldenRod',
  SunflowersHoneydew = 'Sunflowers-Honeydew',
  SunflowersOldLace = 'Sunflowers-OldLace',
}
export const themeSelectionList: ThemeOption[] = [
  {
    label: 'Custom Design',
    value: ThemeOptionValue.CustomDesign,
    color: '#FFFFFF',
  },
  {
    label: 'Blush Celebration - Navy',
    value: ThemeOptionValue.BlushCelebrationNavy,
    color: '#264170',
  },
  {
    label: 'Blush Celebration - Pink',
    value: ThemeOptionValue.BlushCelebrationPink,
    color: '#E3D2D5',
  },
  {
    label: 'Lilacs - Cool Grey',
    value: ThemeOptionValue.LilacsCoolGrey,
    color: '#A5A6C4',
  },
  {
    label: 'Lilacs - Ghost White',
    value: ThemeOptionValue.LilacsGhostWhite,
    color: '#EFEFF7',
  },
  {
    label: 'Lilacs - Wisteria',
    value: ThemeOptionValue.LilacsWisteria,
    color: '#B2A1CB',
  },
  {
    label: 'Lovely Lace - Latte Froth',
    value: ThemeOptionValue.LovelyLaceLatteFroth,
    color: '#ECE6E1',
  },
  {
    label: 'Lovely Lace - Pudra',
    value: ThemeOptionValue.LovelyLacePudra,
    color: '#EBD6D6',
  },
  {
    label: 'Modern Copper - Black',
    value: ThemeOptionValue.ModernCopperBlack,
    color: '#0A0B0D',
  },
  {
    label: 'Modern Copper - Gold',
    value: ThemeOptionValue.ModernCopperGold,
    color: '#6F4827',
  },
  {
    label: 'Palm Leaves - Ivory',
    value: ThemeOptionValue.PalmLeavesIvory,
    color: '#F1F0EC',
  },
  {
    label: 'Palm Leaves - Powder Blue',
    value: ThemeOptionValue.PalmLeavesPowderBlue,
    color: '#C0CFCB',
  },
  {
    label: 'Seashells - Latte Froth',
    value: ThemeOptionValue.SeashellsLatteFroth,
    color: '#F3F0E7',
  },
  {
    label: 'Seashells - Chanoyu',
    value: ThemeOptionValue.SeashellsChanoyu,
    color: '#EFE7D2',
  },
  {
    label: 'Seashells - Bauhaus Buff',
    value: ThemeOptionValue.SeashellsBauhausBuff,
    color: '#CEB29C',
  },
  {
    label: 'Seashells - Skydiver',
    value: ThemeOptionValue.SeashellsSkydiver,
    color: '#83B0D2',
  },
  {
    label: 'Spring - Light Pink',
    value: ThemeOptionValue.SpringLightPink,
    color: '#FFEBEA',
  },
  {
    label: 'Spring - Sand',
    value: ThemeOptionValue.SpringSand,
    color: '#FBF0DF',
  },
  {
    label: 'Sunflowers - Golden Rod',
    value: ThemeOptionValue.SunflowersGoldenRod,
    color: '#E0A632',
  },
  {
    label: 'Sunflowers - Honeydew',
    value: ThemeOptionValue.SunflowersHoneydew,
    color: '#E9F6EB',
  },
  {
    label: 'Sunflowers - Old Lace',
    value: ThemeOptionValue.SunflowersOldLace,
    color: '#FFF8E9',
  },
];

export const colors = [
  '#7a9b8f',
  '#d8f0d4',
  '#071b62',
  '#dff4fd',
  '#8f6f92',
  '#d7b7d5',
  '#fadfe3',
  '#6a211f',
  '#ffa989',
  '#e8bf42',
  '#ac7222',
  '#f2ebd4',
  '#142423',
  '#949696',
  '#f3f4f7',
];

export const headerFonts = [
  'Clicker Script',
  'Rouge Script',
  'Lavishly Yours',
  'Moon Dance',
  'Beau Rivage',
  'Meow Script',
  'Petit Formal Script',
  'Carrois Gothic SC',
  'Julius Sans One',
  'Gabriela',
  'Bellota',
  'Vast Shadow',
  'Jacques Francois Shadow',
];

export const bodyFonts = ['Barlow', 'Nunito', 'Abhaya Libre', 'Nixie One'];

export interface TitlePositionType {
  label: string;
  value: TitlePositionValue;
}

export enum TitlePositionValue {
  Center = 'Center',
  TopLeft = 'Top-Left',
  TopRight = 'Top-Right',
  BottomLeft = 'Bottom-Left',
  BottomRight = 'Bottom-Right',
  TopCenter = 'Top-Center',
  BottomCenter = 'Bottom-Center',
}

export const titlePositionTypes: TitlePositionType[] = [
  { label: 'Centered', value: TitlePositionValue.Center },
  { label: 'Top Left', value: TitlePositionValue.TopLeft },
  { label: 'Top Right', value: TitlePositionValue.TopRight },
  { label: 'Bottom Left', value: TitlePositionValue.BottomLeft },
  { label: 'Bottom Right', value: TitlePositionValue.BottomRight },
  { label: 'Top Center', value: TitlePositionValue.TopCenter },
  { label: 'Bottom Center', value: TitlePositionValue.BottomCenter },
];

export enum WebsiteThemeType {
  Preset = 'Preset',
  Custom = 'Custom',
  Default = 'Default',
}
