import React, { memo } from 'react';
import { IconCheckmark } from '../Icons/IconCheckmark';
import { Box, Typography, useTheme } from '@mui/material';

interface Props {
  status: string;
}

enum COLOR {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  DEFAULT = 'default',
}

enum STATUS {
  ACTIVE = 'active',
  PENDING = 'pending',
  TOO_FAR_OUT = 'toofarout',
  CANCELLED = 'cancelled',
  PAST_DUE = 'pastdue',
}

const mapStatusToProps: Record<STATUS, { color: COLOR; label: string }> = {
  [STATUS.ACTIVE]: {
    color: COLOR.SUCCESS,
    label: 'Booked',
  },
  [STATUS.PENDING]: {
    color: COLOR.WARNING,
    label: 'Pending',
  },
  [STATUS.TOO_FAR_OUT]: {
    color: COLOR.SUCCESS,
    label: 'Active',
  },
  [STATUS.CANCELLED]: {
    color: COLOR.ERROR,
    label: 'Cancelled',
  },
  [STATUS.PAST_DUE]: {
    color: COLOR.WARNING,
    label: 'Past Due',
  },
};

export const RoomStatusChip = memo((props: Props) => {
  const theme = useTheme();
  const status = props.status.toLowerCase() as STATUS;
  const { color = COLOR.DEFAULT, label = status } =
    mapStatusToProps[status] || {};
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        padding: '0.3rem',
        width: 'fit-content',
        background: theme?.palette[color]?.light,
        alignItems: 'center',
        borderRadius: '2px',
        '& svg': {
          width: '1rem',
          height: '1rem',
          marginRight: '0.25rem',
          fill: theme?.palette[color]?.light,
          stroke: theme?.palette[color]?.dark,
        },
      }}
    >
      <IconCheckmark stroke={theme?.palette[color]?.dark} />
      <Typography
        variant={'body2'}
        sx={{
          color: theme?.palette[color]?.dark,
          fontSize: '0.75rem',
          whiteSpace: 'nowrap',
        }}
      >
        {label}
      </Typography>
    </Box>
  );
});
