import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Box, Breadcrumbs, Link, Typography } from '@mui/material';
import WeddingWebsiteSkeleton from 'app/components/Skeletons/WeddingWebsiteSkeleton';
import React from 'react';
import { isBrowser } from 'react-device-detect';
import { useSelector } from 'react-redux';
import AppRoute from '../../../../utils/appRoute';
import { Menu, pageNameList } from '../../../components/EditYourWebsiteMenu';
import { Layout } from '../../../components/Layout';
import './fonts.css';

import { selectLoading, selectWebsiteDetail } from './slice/selectors';
import { WebsiteDetail } from './slice/types';
import { useIsMobile } from 'utils/responsiveHelper';
import { FadeInContainer } from 'app/components/Misc/FadeInContainer';
import { CMSMaintenanceMessage } from './CMSMaintenanceMessage';
import { useEnvFeatureFlag } from 'utils/envFeatureFlags/hooks/useEnvFeatureFlag';

export function WeddingWebsiteLayout({
  title,
  children,
  currentPageName,
  contentBlock,
}: {
  title: string;
  children: React.ReactNode;
  currentPageName?: pageNameList;
  contentBlock?: React.ReactNode;
}) {
  const websiteDetail: WebsiteDetail = useSelector(selectWebsiteDetail);
  const loading = useSelector(selectLoading);
  const isMobile = useIsMobile('md');
  const isCmsDisabled = useEnvFeatureFlag('CMS_EDITING_DISABLED');
  return (
    <Layout
      title="Wedding Website"
      hasContentPadding={false}
      alignment="left"
      fullBleed
    >
      {isCmsDisabled ? (
        <CMSMaintenanceMessage />
      ) : (
        <>
          <Box
            position={'relative'}
            display={'flex'}
            width={'100%'}
            flexDirection={isMobile ? 'column' : 'row'}
          >
            <Box
              height={isMobile ? '51px' : 'auto'}
              minWidth={300}
              p={!isMobile && '16px 32px'}
              borderRight={isBrowser && '1px solid #e4e8e8'}
            >
              <Menu currentPageName={currentPageName} />
            </Box>
            {!loading && websiteDetail ? (
              <Box
                p={isMobile ? '16px' : '32px 48px 16px 48px'}
                flexGrow={isBrowser && 1}
              >
                <FadeInContainer>
                  <Box mb={2}>
                    <Breadcrumbs
                      separator={<NavigateNextIcon fontSize="small" />}
                      aria-label="breadcrumb"
                      sx={{ ' li::before': { content: '""' } }}
                    >
                      <Link
                        underline="hover"
                        color="inherit"
                        href={AppRoute.weddingWebsitePage()}
                      >
                        Wedding Website
                      </Link>
                      <Typography color={'#2d3031'} fontWeight={500}>
                        {title}
                      </Typography>
                    </Breadcrumbs>
                  </Box>
                  <Typography variant={'h4'}>{title}</Typography>
                  {children}
                </FadeInContainer>
              </Box>
            ) : (
              <Box width={'100%'}>
                <WeddingWebsiteSkeleton />
              </Box>
            )}
          </Box>
          {contentBlock}{' '}
        </>
      )}
    </Layout>
  );
}
