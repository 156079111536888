import {
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControl,
  InputAdornment,
  lighten,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
  Grid,
  Chip,
} from '@mui/material';
import { IconPlus } from 'app/components/Icons/IconPlus';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import AppRoute from '../../../../utils/appRoute';
import { pageNameList } from '../../../components/EditYourWebsiteMenu';
import { IconDelete } from '../../../components/Icons/IconDelete';
import { IconImageUpload } from '../../../components/Icons/IconImageUpload';
import { RoundedButton } from '../../../components/RoundedButton';
import './fonts.css';
import { ImageSelectionModal } from './ImageSelectionModal';
import { ImageUploadModal } from './ImageUploadModal';
import { useWeddingWebsiteSlice } from './slice';
import {
  selectIsUpdating,
  selectWebsiteDetail,
  selectWebsiteImages,
} from './slice/selectors';
import { WebsiteDetail, WebsiteImage } from './slice/types';
import { WeddingWebsitePreviewIFrame } from './WeddingWebsitePreviewIFrame';
import { WeddingWebsiteLayout } from './WeddingWebsiteLayout';
import {
  ThemeOption,
  themeSelectionList,
  colors,
  headerFonts,
  bodyFonts,
  titlePositionTypes,
  TitlePositionType,
  WebsiteThemeType,
} from './themeConstants';

export function WeddingWebsiteDesign() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { actions } = useWeddingWebsiteSlice();
  const websiteDetail: WebsiteDetail = useSelector(selectWebsiteDetail);

  const [weddingWebsitePreviewKey, setWeddingWebsitePreviewKey] = useState(1);

  const [layoutWithBorder, setLayoutWithBorder] = useState(false);
  const [singlePageView, setSinglePageView] = useState(false);
  const [primaryColor, setPrimaryColor] = useState('');
  const [headerColor, setHeaderColor] = useState('');
  const [secondaryColor, setSecondaryColor] = useState('');
  const [primaryColorPickerOpen, setPrimaryColorPickerOpen] = useState(false);
  const [headerColorPickerOpen, setHeaderColorPickerOpen] = useState(false);
  const [secondaryColorPickerOpen, setSecondaryColorPickerOpen] =
    useState(false);
  const [bodyTextFonts, setBodyTextFonts] = useState(null);
  const [headerTextFonts, setHeaderTextFonts] = useState(null);
  const [uploadedImage, setUploadedImage] = useState<string>('');
  const [userSelectedImage, setUserSelectedImage] =
    useState<WebsiteImage>(null);
  const [weddingTitle, setWeddingTitle] = useState('');
  const [weddingTitleError, setWeddingTitleError] = useState(null);
  const [weddingTitlePosition, setWeddingTitlePosition] =
    useState<TitlePositionType>(
      titlePositionTypes.find(pos => pos.value === 'Center') ||
        titlePositionTypes[0],
    );
  const CUSTOM_DESIGN = 'CustomDesign';
  const [weddingDateHide, setWeddingDateHide] = useState<boolean>(false);
  const [themeSelection, setThemeSelection] = useState<ThemeOption>(
    themeSelectionList.find(theme => theme.value === CUSTOM_DESIGN) ||
      themeSelectionList[0],
  );
  const isUpdating = useSelector(selectIsUpdating);

  useEffect(() => {
    dispatch(actions.loadWebsiteImages());
  }, [dispatch, actions]);

  useEffect(() => {
    if (!websiteDetail) return;
    setHeaderColor(websiteDetail.headerColor);
    setPrimaryColor(websiteDetail.mainColor);
    setSecondaryColor(websiteDetail.secondaryColor);
    setHeaderTextFonts(websiteDetail.headerFontStyle);
    setBodyTextFonts(websiteDetail.bodyFontStyle);
    setLayoutWithBorder(websiteDetail.layoutOrientation === 'Border');
    setSinglePageView(
      websiteDetail.themeType === WebsiteThemeType.Preset
        ? true
        : websiteDetail.isSinglePageView,
    );
    setUserSelectedImage({ url: websiteDetail.backgroundImage, uid: null });
    setWeddingTitle(websiteDetail.weddingTitle);
    setWeddingTitlePosition(
      titlePositionTypes.find(
        pos => pos.value === websiteDetail.weddingTitlePosition,
      ) || titlePositionTypes[0],
    );
    setWeddingDateHide(websiteDetail.weddingDateHide);
    // Conditionally set ThemeSelection
    if (websiteDetail.themeType === WebsiteThemeType.Preset) {
      const matchingTheme = themeSelectionList.find(
        theme => theme.color === websiteDetail.mainColor,
      );
      setThemeSelection(
        matchingTheme ||
          themeSelectionList.find(theme => theme.value === CUSTOM_DESIGN),
      );
    } else {
      setThemeSelection(
        themeSelectionList.find(theme => theme.value === CUSTOM_DESIGN),
      );
    }
  }, [websiteDetail]);

  useEffect(() => {
    if (themeSelection.value !== 'CustomDesign') setSinglePageView(true);
  }, [themeSelection]);
  const backgroundImageUploadRequest = uploadedImage
    ? {
        FileName: 'wedding-website-bg-image.jpg',
        Type: 'ClientPhoto',
        ItemIndex: 0,
        base64EncodedImageData: uploadedImage.split(',')[1],
      }
    : {};

  const choosenBackgroundImageKey = userSelectedImage?.uid
    ? userSelectedImage.uid
    : '';

  const onChangeWeddingTitle = weddingTitle => {
    if (weddingTitle && weddingTitle.length > 150)
      setWeddingTitleError('Max 150 chars allowed');
    else setWeddingTitleError(null);
    setWeddingTitle(weddingTitle);
  };

  const handleUpdate = () => {
    if (weddingTitleError) return;
    const formData = {
      // need to send to api without .destify.com for now
      domainName: websiteDetail.websiteUrl,
      headerColor,
      mainColor:
        themeSelection.value !== CUSTOM_DESIGN
          ? themeSelection.color
          : primaryColor,
      secondaryColor,
      bodyTextFonts,
      headerTextFonts,
      isSinglePageView: singlePageView,
      layoutOrientation: layoutWithBorder ? 'Border' : 'NoBorder',
      themeType:
        themeSelection.value === CUSTOM_DESIGN
          ? WebsiteThemeType.Custom
          : WebsiteThemeType.Preset,
      themeName:
        themeSelection.value === CUSTOM_DESIGN
          ? WebsiteThemeType.Custom
          : themeSelection.value,
      backgroundImageUploadRequest,
      choosenBackgroundImageKey,
      weddingTitle,
      weddingTitlePosition: weddingTitlePosition.value,
      weddingDateHide,
    };
    dispatch(
      actions.requestWebsiteEdit({
        formData,
        history,
        route: AppRoute.weddingWebsiteDesignPage(),
      }),
    );
    toggleWebsitePreviewKey();
  };

  const [showImageSelectionModal, setShowImageSelectionModal] = useState(false);
  const websiteImages = useSelector(selectWebsiteImages);

  function onSelectImageSave(index) {
    setShowImageSelectionModal(false);

    setUploadedImage('');
    setUserSelectedImage(websiteImages[index]);
  }

  const [openImageUploadModal, setOpenImageUploadModal] =
    useState<boolean>(false);

  const onUploadImage = useCallback(base64Image => {
    setUserSelectedImage(null);
    setUploadedImage(base64Image);
  }, []);

  const toggleWebsitePreviewKey = () => {
    setWeddingWebsitePreviewKey(prev => 1 - prev);
  };

  return (
    <WeddingWebsiteLayout
      title={'Design your Wedding Website'}
      currentPageName={pageNameList.design}
    >
      <Box mb={2}>
        <WeddingWebsitePreviewIFrame
          websiteUrl={websiteDetail?.websiteUrl}
          key={weddingWebsitePreviewKey}
        />
      </Box>
      <Box mb={2}>
        <Typography
          fontFamily={'"Nunito", sans-serif'}
          fontWeight={700}
          fontSize={[16, 18]}
          lineHeight={1.364}
          mb={2}
        >
          Layout Orientation
        </Typography>
        <Box display={'flex'} gap={2} flexDirection={'row'}>
          <LayoutContainer>
            <LayoutWithBorders />
            <Typography variant={'body2'} textAlign={'center'}>
              With borders
            </Typography>
            <Checkbox
              checked={layoutWithBorder}
              onChange={() => setLayoutWithBorder(!layoutWithBorder)}
            />
          </LayoutContainer>
          <LayoutContainer>
            <LayoutWithoutBorders />
            <Typography variant={'body2'} textAlign={'center'}>
              Without borders
            </Typography>
            <Checkbox
              checked={!layoutWithBorder}
              onChange={() => setLayoutWithBorder(!layoutWithBorder)}
            />
          </LayoutContainer>
        </Box>
      </Box>
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'flex-start'}
        alignItems={'center'}
        mb={2}
      >
        <Checkbox
          disabled={themeSelection.value !== 'CustomDesign'}
          checked={singlePageView}
          onChange={() => setSinglePageView(!singlePageView)}
        />
        <Typography variant={'body1'}>Single Page View</Typography>
      </Box>
      <Grid container direction="row" mb={2} gap={2}>
        <Grid item sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              flexDirection: 'column',
              marginBottom: '20px',
            }}
          >
            <Typography
              fontFamily={'"Nunito", sans-serif'}
              fontWeight={700}
              fontSize={[16, 18]}
              lineHeight={1.364}
              mb={2}
              sx={{ textAlign: 'center' }}
            >
              Design
            </Typography>
            <Typography
              fontFamily={'"Nunito", sans-serif'}
              fontWeight={500}
              fontSize={[12, 14]}
              lineHeight={1.364}
              mb={2}
              sx={{ textAlign: 'center' }}
            >
              Select from our professionally designed themes or <br />
              customize your own design!
            </Typography>
            <Select
              id="theme-selection"
              value={themeSelection.value}
              onChange={e => {
                const newTheme = themeSelectionList.find(
                  theme => theme.value === e.target.value,
                );
                if (newTheme) {
                  setThemeSelection(newTheme);
                }
              }}
              sx={{ width: '70%', alignSelf: 'center' }}
            >
              {themeSelectionList.map((theme: ThemeOption) => (
                <MenuItem value={theme.value} key={theme.value}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                  >
                    {theme.label}
                    <Chip
                      size="small"
                      style={{
                        backgroundColor: theme.color,
                        marginLeft: '8px',
                        borderRadius: '2px', // Making it square-shaped
                        width: '20px', // Optional, to ensure it's square
                        height: '20px', // Optional, to ensure it's square
                      }}
                    />
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </Box>

          {themeSelection.value === CUSTOM_DESIGN && (
            <Box mb={2}>
              <FormRow>
                <TitleText>Header Font</TitleText>
                <FontPicker
                  value={headerTextFonts}
                  onChange={e => setHeaderTextFonts(e.target.value)}
                  options={headerFonts.map(font => ({
                    label: font,
                    value: font,
                  }))}
                />
              </FormRow>
              <FormRow>
                <TitleText>Header Color</TitleText>
                <TextField
                  id="input-with-icon-textfield"
                  data-testid="header-color-input"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <ColorIcon color={headerColor} />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  value={headerColor}
                  onClick={() => {
                    setHeaderColorPickerOpen(!headerColorPickerOpen);
                    setSecondaryColorPickerOpen(false);
                    setPrimaryColorPickerOpen(false);
                  }}
                  onChange={e => setHeaderColor(e.target.value)}
                />
              </FormRow>
              <CollapsibleColorPicker
                show={headerColorPickerOpen}
                colorToUpdate={headerColor}
                onUpdateColor={setHeaderColor}
              />
              <FormRow>
                <TitleText>Body Font</TitleText>
                <FontPicker
                  value={bodyTextFonts}
                  onChange={e => setBodyTextFonts(e.target.value)}
                  options={bodyFonts.map(font => ({
                    label: font,
                    value: font,
                  }))}
                />
              </FormRow>
              <FormRow>
                <TitleText>Accent Color</TitleText>
                <TextField
                  id="input-with-icon-textfield"
                  data-testid="primary-color-input"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <ColorIcon color={primaryColor} />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  value={primaryColor}
                  onClick={() => {
                    setPrimaryColorPickerOpen(!primaryColorPickerOpen);
                    setSecondaryColorPickerOpen(false);
                    setHeaderColorPickerOpen(false);
                  }}
                  onChange={e => setPrimaryColor(e.target.value)}
                />
              </FormRow>
              <CollapsibleColorPicker
                show={primaryColorPickerOpen}
                colorToUpdate={primaryColor}
                onUpdateColor={setPrimaryColor}
              />
              <FormRow>
                <TitleText>Background Color</TitleText>
                <TextField
                  id="input-with-icon-textfield"
                  data-testid="secondary-color-input"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <ColorIcon color={secondaryColor} />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  value={secondaryColor}
                  onClick={() => {
                    setSecondaryColorPickerOpen(!secondaryColorPickerOpen);
                    setPrimaryColorPickerOpen(false);
                    setHeaderColorPickerOpen(false);
                  }}
                  onChange={e => setSecondaryColor(e.target.value)}
                />
              </FormRow>
              <CollapsibleColorPicker
                show={secondaryColorPickerOpen}
                colorToUpdate={secondaryColor}
                onUpdateColor={setSecondaryColor}
              />
            </Box>
          )}
        </Grid>
        <Grid item sx={{ display: 'flex', flex: 2, flexDirection: 'column' }}>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              flexDirection: 'column',
              marginBottom: '20px',
            }}
          >
            <Typography
              fontFamily={'"Nunito", sans-serif'}
              fontWeight={700}
              fontSize={[16, 18]}
              lineHeight={1.364}
              mb={2}
              sx={{ textAlign: 'center' }}
            >
              Content
            </Typography>
          </Box>
          <Grid item sx={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
            <Grid item sx={{ display: 'flex', flex: 1 }}>
              <Box mb={2}>
                <Typography
                  fontFamily={'"Nunito", sans-serif'}
                  fontWeight={700}
                  fontSize={[16, 18]}
                  lineHeight={1.364}
                  mb={2}
                >
                  Background Image
                </Typography>
                {uploadedImage || userSelectedImage ? (
                  <Box
                    sx={{
                      position: 'relative',
                      width: [240, 340],
                      height: 240,
                      borderRadius: '20px',
                      backgroundImage: `url("${
                        uploadedImage
                          ? uploadedImage
                          : userSelectedImage
                          ? userSelectedImage.url
                          : websiteDetail?.backgroundImage ||
                            '/img/placeholder2.png'
                      }")`,
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                      backgroundSize: 'cover',
                    }}
                  >
                    <Box
                      sx={{
                        position: 'absolute',
                        top: '8px',
                        right: '8px',
                        zIndex: 9,
                        cursor: 'pointer',
                      }}
                      data-testid="save-the-date-delete-image-button"
                      onClick={() => {
                        setUploadedImage(null);
                        setUserSelectedImage(null);
                      }}
                    >
                      <IconDelete />
                    </Box>
                  </Box>
                ) : (
                  <Box
                    gap={0.5}
                    sx={{
                      width: [240, 340],
                      height: 240,
                      border: '1px dashed #949696',
                      borderRadius: '20px',
                      alignItems: 'center',
                      justifyContent: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <IconImageUpload />
                    <label>
                      <Link
                        sx={{ cursor: 'pointer' }}
                        onClick={() => setOpenImageUploadModal(true)}
                        data-testid="upload-image-button"
                      >
                        Upload the Image
                      </Link>{' '}
                      or{' '}
                      <Link
                        sx={{ cursor: 'pointer' }}
                        onClick={() => {
                          setShowImageSelectionModal(true);
                        }}
                        data-testid="select-image-button"
                      >
                        Select from gallery
                      </Link>
                    </label>
                    <Typography
                      align="center"
                      variant="caption"
                      whiteSpace={'pre'}
                    >
                      {
                        '[ only jpeg (or jpg), png and gif formats are supported ]\nMaximum file upload size is 5 MB'
                      }
                    </Typography>
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item sx={{ display: 'flex', flex: 1 }}>
              <Box>
                <FormRow>
                  <TitleText>Image Headline Text</TitleText>
                  <TextField
                    value={weddingTitle}
                    variant="outlined"
                    onChange={e => onChangeWeddingTitle(e.target.value)}
                    error={!!weddingTitleError}
                    helperText={weddingTitleError}
                    disabled={themeSelection.value !== CUSTOM_DESIGN}
                  />
                </FormRow>
                <FormRow>
                  <TitleText>Headline Position</TitleText>
                  <FormControl>
                    <Select
                      id="headline-position"
                      value={weddingTitlePosition.value}
                      onChange={e => {
                        const newPosition = titlePositionTypes.find(
                          pos => pos.value === e.target.value,
                        );
                        if (newPosition) {
                          setWeddingTitlePosition(newPosition);
                        }
                      }}
                      disabled={themeSelection.value !== CUSTOM_DESIGN}
                    >
                      {titlePositionTypes.map((position: TitlePositionType) => (
                        <MenuItem value={position.value} key={position.value}>
                          {position.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </FormRow>
                <FormRow>
                  <TitleText>Hide Wedding Date</TitleText>
                  <FormControl>
                    <Select
                      id="hide-wedding-date"
                      value={weddingDateHide.toString()}
                      onChange={e =>
                        setWeddingDateHide(e.target.value === 'true')
                      }
                      disabled={themeSelection.value !== CUSTOM_DESIGN}
                    >
                      <MenuItem value={'true'} key={'Yes'}>
                        Yes
                      </MenuItem>
                      <MenuItem value={'false'} key={'No'}>
                        No
                      </MenuItem>
                    </Select>
                  </FormControl>
                </FormRow>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <RoundedButton
        loading={isUpdating}
        onClick={handleUpdate}
        disabled={!!weddingTitleError}
      >
        Save & Publish
      </RoundedButton>

      <ImageSelectionModal
        onClose={() => {
          setShowImageSelectionModal(false);
        }}
        open={showImageSelectionModal}
        images={websiteImages}
        onSelectImageSave={onSelectImageSave}
      />
      <ImageUploadModal
        open={openImageUploadModal}
        onClose={() => setOpenImageUploadModal(false)}
        onUpload={onUploadImage}
      />
    </WeddingWebsiteLayout>
  );
}

const FormRow = ({ children, mt = 0 }) => {
  return (
    <Box
      mt={mt}
      sx={{
        display: 'grid',
        gridTemplateColumns: '150px 160px',
        alignItems: 'center',
        gap: 2,
      }}
    >
      {children}
    </Box>
  );
};
const LayoutContainer = ({ children }) => {
  return (
    <Box
      sx={{
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-around',
        backgroundColor: '#f2f2f2',
        borderRadius: 1,
        minHeight: '150px',
      }}
      flex={1}
      display={'flex'}
      p={2}
    >
      {children}
    </Box>
  );
};

const LayoutWithBorders = () => {
  return (
    <Box
      sx={{
        width: '87px',
        height: '47px',
        border: '1px solid ',
        justifyContent: 'center',
      }}
      display={'flex'}
    >
      <Box
        sx={{
          width: '54px',
          borderLeft: '1px solid ',
          borderRight: '1px solid ',
        }}
      />
    </Box>
  );
};

const LayoutWithoutBorders = () => {
  return (
    <Box
      sx={{
        width: '87px',
        height: '47px',
        border: '1px solid ',
      }}
      display={'flex'}
    >
      <Box
        sx={{
          height: '9px',
          width: '100%',
          borderBottom: '1px solid ',
        }}
      />
    </Box>
  );
};

const TitleText = ({ children }) => (
  <Typography variant={'body1'}>{children}</Typography>
);

const ColorIcon = ({ color }) => {
  return (
    <Box
      sx={{
        width: 20,
        height: 20,
        backgroundColor: color,
      }}
    />
  );
};

const ColorButton = ({ color, onClick }) => (
  <Button
    onClick={onClick}
    sx={{
      width: '2.5rem',
      minWidth: '2.5rem',
      height: '2.5rem',
      backgroundColor: color,
      borderRadius: '50%',
      margin: '0.5rem',
      '&:hover': {
        backgroundColor: lighten(color, 0.3),
        color: 'white',
      },
    }}
  />
);

const HiddenColorInput = styled('input').attrs({ type: 'color' })`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  border: 1px solid #f3f4f7;
  background-color: transparent;
`;

const ColorButtonDecorator = styled.button`
  width: 2.5rem;
  min-width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  border: 1px solid #f3f4f7;
  background-color: #f3f4f7;
  position: absolute;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

// In order to make this button behave like a color picker, we need to hide the input and
// overlay a button on top of it.
const AddColorButton = ({ onChange }) => (
  <Box position={'relative'} margin={'0.5rem'}>
    <ColorButtonDecorator>
      <IconPlus bgColor={'#f3f4f7'} color={'#3c3c43'} />
    </ColorButtonDecorator>
    <HiddenColorInput onChange={onChange} />
  </Box>
);

const CollapsibleColorPicker = ({ show, colorToUpdate, onUpdateColor }) => (
  <Collapse in={show}>
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        gap: 2,
      }}
    >
      <Box>
        <Box
          bgcolor={colorToUpdate}
          width={'7rem'}
          height={'7rem'}
          borderRadius={'1rem'}
        ></Box>
      </Box>
      <Box display={'flex'} flexDirection={'row'} flexWrap={'wrap'}>
        {colors.map((color, idx) => (
          <ColorButton
            color={color}
            key={idx}
            data-testid={`color-button-${idx}`}
            onClick={() => onUpdateColor(color)}
          />
        ))}
        <AddColorButton onChange={e => onUpdateColor(e.target.value)} />
      </Box>
    </Box>
  </Collapse>
);

const FontPicker = ({ value, onChange, options }) => (
  <FormControl>
    <Select
      labelId="font-picker-label"
      id="font-picker"
      value={value || ''}
      onChange={onChange}
      sx={{ fontFamily: value }}
    >
      {options.map((option, idx) => (
        <MenuItem
          value={option.value}
          key={idx}
          sx={{ fontFamily: option.value }}
        >
          {option.label}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);
